import React, {Component} from "react"
import { Link } from "react-router-dom";

class PrivacyPolicyView extends Component {
    render() {
        const backButton = ((window.localStorage.getItem('consent') || false) ? null : 
            <div>
                <div className="" style={{margin: "2rem"}}>
                    <Link to={"/aboutapp"} className="btn btn-primary btn-rounded" style={{color: 'white'}}>&lt; terug</Link>
                </div>
            </div>
        );

        return (
            <div className="content container">
                <div id="carouselExampleControls" className="carousel slide full-width-carrousel" data-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <div style={{background: "url('/images/aboutapp/header2.png') no-repeat center"}}><span style={{display: "inline-block", height: "10rem"}}></span></div>
                        </div>
                    </div>
                    {/* <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="sr-only">Previous</span>
                    </a>
                    <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="sr-only">Next</span>
                    </a> */}
                </div>

                {backButton}
                <h2>Privacyverklaring BV PINGUINO</h2>

<p><h3>Samenvatting</h3></p>
<p>BV PINGUINO gevestigd te Nobels-peelmanstraat 10, 9100 Sint-Niklaas is
verantwoordelijke voor de verwerking van uw Persoonsgegevens, via haar website
https://www.pinguino.be/.</p>
<p>BV PINGUINO verwerkt gegevens van de bezoekers &/of gebruikers voor volgende
doeleinden:</p>
<ul>
    <li>Het uitvoeren en aanbieden van de diensten van BV PINGUINO</li>
    <li>Beheer van aanvragen tot informatie</li>
    <li>Verbetering van de werking van BV PINGUINO & de Website</li>
    <li>Versturen van een nieuwsbrief/reclame</li>
    <li>Om alle andere diensten te verlenen waarom u heeft verzocht</li>
    <li>Compliance met de geldende wetgeving</li>
    <li>Administratie van het personeel en de tussenpersonen</li>
</ul>
<p>U kan steeds verzoeken om inzage van - en rectificatie of wissing van Persoonsgegevens
of om beperking van de u betreffende verwerking, alsmede het recht tegen verwerking van
bezwaar te maken en het recht op gegevensoverdraagbaarheid en het recht om steeds
Uw toestemming in te trekken.</p>
<p>Persoonsgegevens worden doorgegeven aan volgende ontvangers:</p>
<ul>
    <li>Verwerkers die voorzien in statistisch onderzoek en analyses</li>
    <li>Verwerkers die voorzien in hosting van deze Website en aanverwante databases;</li>
    <li>Verwerkers en verwerkingsverantwoordelijken die voor BV PINGUINO
optreden als onderaannemer bij het uitvoeren van de Diensten.</li>
    <li>Vennootschappen die verbonden zijn met BV PINGUINO</li>
</ul>

<p><h3>1. Definities</h3></p>
<p>In deze Privacyverklaring wordt verstaan onder:</p>
<ol>
    <li><b>BV PINGUINO</b>: BV PINGUINO NV, met haar maatschappelijk zetel te
Nobels-peelmanstraat 10, 9100 Sint-Niklaas en ingeschreven bij de Kruispuntbank van
Ondernemingen onder het nummer 0823.540.391</li>
<li><b>Website</b>: De website van BV PINGUINO, https://www.pinguino.be/ en afgeleiden
ontwikkeld en uitgebaat door BV PINGUINO</li>
<li><b>Persoonsgegevens</b>: Iedere informatie betreffende een geïdentificeerde of
identificeerbare natuurlijke persoon</li>
<li><b>Verwerking</b>: elke bewerking of ieder geheel van bewerkingen, al dan niet uitgevoerd
met behulp van geautomatiseerde processen, en toegepast op de persoonsgegevens
van natuurlijke personen, zoals het inzamelen, vastleggen, ordenen, bewaren,
bijwerken, opslaan, wijzigen, wissen, consulteren, meedelen en gebruiken, verstrekken
door middel van doorzending, verspreiden of op enigerlei andere wijze ter beschikking
stellen, samenbrengen, met elkaar in verband brengen, afschermen of vernietigen</li>
</ol>

<p><h3>2. Verwerking persoonsgegevens</h3></p>
<p>Deze Privacyverklaring is van toepassing op alle verwerkingen die plaatsvinden d.m.v. en ten
gevolge van het gebruik van de website door de bezoeker.</p>
<p>BV PINGUINO hecht het nodige belang aan het respecteren en het beschermen van
uw privacy en aan de vertrouwelijkheid en veiligheid van uw persoonsgegevens.</p>
<p>Op de verwerking van Uw Persoonsgegevens is tot 25 mei 2018 de Wet van 8 december 1992
met betrekking tot de bescherming van de persoonlijke levenssfeer ten opzichte van de
verwerking van persoonsgegevens (hierna ‘de Wet van 8 december 1992’) van toepassing en
vanaf 25 mei 2018 de Verordening (EU) 2016/679 (hierna ‘de GDPR’). Naar deze wetgeving
wordt verwezen als ‘Gegevensbeschermingswetgeving’.
</p>
<p>BV PINGUINO treedt hierbij op als verantwoordelijke voor de verwerking.</p>

<p><h3>3. Welke persoonsgegevens</h3></p>
<p><h4>a) Persoonsgegevens door de bezoeker zelf verstrekt</h4></p>
<p>BV PINGUINO kan via zijn Website de volgende Persoonsgegevens verwerken,
inclusief, zonder beperking, via het contactformulier:</p>
<ul>
    <li>Persoonlijke identificatiegegevens, zoals uw naam, e-mailadres, gsm</li>
</ul>
<p>BV PINGUINO kan via zijn Website de volgende Persoonsgegevens verwerken,
inclusief, zonder beperking, via het sollicitatieformulier:</p>
<ul>
    <li>Persoonlijke identificatiegegevens, zoals uw naam, e-mailadres, adres,
rijksregisternummer</li>
<li>Persoonlijke bijzonderheden, zoals uw geboortedatum, nationaliteit</li>
<li>Vrijetijdsactiviteiten en interesses, zoals uw algemene bekwaamheden</li>
<li>Beroepsactiviteiten, zoals uw huidige werkzaamheid</li>
<li>Academische curriculum</li>
<li>Beroepsbekwaamheid, zoals uw getuigschriften</li>
<li>Professionele ervaring</li>
<li>Afbeeldingen, zoals uw foto</li>
</ul>
<p>BV PINGUINO kan via zijn Website de volgende Persoonsgegevens verwerken,
inclusief, zonder beperking, via het aanmelden voor de nieuwsbrief</p>
<ul>
    <li>Persoonlijke identificatiegegevens, zoals uw naam, e-mailadres</li>
</ul>
<p>Indien informatie door de betrokkene zelf wordt verstrekt via een formulier dan zijn de velden
die worden aangeduid met een (*) strik noodzakelijk voor de doeleinden waarvoor ze worden
verwerkt. Indien deze niet worden ingevuld zal er aan de verzending van het formulier geen
gevolg worden gegeven. De overige velden kunnen worden ingevuld om de dienstverlening
van BV PINGUINO te vergemakkelijken, indien deze niet worden ingevuld heeft dit geen
enkele weerslag.</p>

<p><h4>b) Persoonsgegevens bij het plaatsen van bestellingen</h4></p>
<p>BV PINGUINO kan via zijn Website de volgende Persoonsgegevens verwerken,
inclusief, zonder beperking, via het plaatsen van een bestelling door de klant:</p>
<ul>
    <li>Persoonlijke identificatiegegevens, zoals uw naam, adres</li>
    <li>Financiële identificatiegegevens, zoals uw bankrekeningnummer</li>
</ul>
<p>BV PINGUINO kan via zijn Website de volgende Persoonsgegevens verwerken,
inclusief, zonder beperking, via het plaatsen van een reservatie door de klant:</p>
<ul>
    <li>Persoonlijke identificatiegegevens, zoals uw naam, adres</li>
    <li>Financiële identificatiegegevens, zoals uw BTW-nummer</li>
</ul>

<p><h4>c) Informatie over het bezoek en het gebruik van de website</h4></p>
<p>BV PINGUINO kan via zijn Website de volgende Persoonsgegevens verwerken,
inclusief, zonder beperking, via onder andere het gebruik van cookies</p>
<ul>
    <li>Elektronische identificatiegegevens, zoals uw IP-adres, besturingssysteem, browser
type en geografische locatie</li>
</ul>

<p><h3>4. Doeleinden en rechtsgronden</h3></p>
<p>BV PINGUINO verzamelt Persoonsgegevens en verwerkt deze voor één of meerdere
van de volgende doeleinden:</p>

<table>
    <tr>
        <th>Doeleinden</th>
        <th>Rechtsgrond</th>
    </tr>
    <tr>
        <td>Het uitvoeren en aanbieden van de diensten van BV
PINGUINO (zoals beschreven in de Algemene
Voorwaarden);</td>
<td>
Uitvoering overeenkomst
</td>
</tr>
<tr>
    <td>Beheer van aanvragen tot informatie</td>
    <td>Uitvoering overeenkomst</td>
</tr>
<tr>
    <td>Verbetering van de werking van BV PINGUINO & de
Website</td>
<td>Gerechtvaardigd belang<sup>1</sup></td>
</tr>
<tr>
    <td>Versturen van een nieuwsbrief/reclame</td>
    <td>Toestemming</td>
</tr>
<tr>
    <td>Accountadministratie</td>
    <td>Uitvoering overeenkomst</td>
</tr>
<tr>
    <td>Om alle andere diensten te verlenen waarom u heeft
verzocht</td>
<td>Uitvoering overeenkomst</td>
</tr>
<tr>
    <td>Compliance met de geldende wetgeving</td>
    <td>Wettelijke verplichting</td>
</tr>
<tr>
    <td>Administratie van het personeel en de tussenpersonen</td>
    <td>Uitvoering overeenkomst</td>
</tr>
</table>
<p><sup>1</sup> Verbetering is noodzakelijk voor de belangen van BV PINGUINO. Verbetering van de werking
van BV PINGUINO is ook in het voordeel van de betrokkenen, er is geen sprake van nadeel aan
de rechten en belangen van de betrokkenen.
</p>
<p>Indien BV PINGUINO persoonsgegevens zou wensen te gebruiken voor een
incompatibel nieuw doeleinde, zal BV PINGUINO voorafgaandelijk aan dit nieuwe
gebruik hiervoor uw toestemming vragen.
</p>
<p>Wanneer de verwerking is gebaseerd op uw toestemming, dan heeft u steeds het recht de
toestemming te allen tijde in te trekken, zonder dat dit afbreuk doet aan de rechtmatigheid van
de verwerking op basis van de toestemming vóór de intrekking daarvan.</p>

<p><h3>5. Doorgifte persoonsgegevens</h3></p>
<p>Persoonsgegevens worden alleen door BV PINGUINO verwerkt voor intern gebruik voor
de hierboven beschreven doeleinden. Wij verhuren of verkopen uw Persoonsgegevens niet
aan derden voor gebruik voor eigen doeleinden.</p>
<p>Binnen BV PINGUINO worden de persoonsgegevens enkel beschikbaar gesteld aan
haar personeel op een ‘need to know’ basis.</p>
<p>BV PINGUINO kan gebruik maken van verwerkers om uw Persoonsgegevens te
verwerken. Verwerkers zijn natuurlijke of rechtspersonen, overheden, agentschappen of
andere organen die namens de BV PINGUINO Persoonsgegevens verwerken.</p>
<p>BV PINGUINO geeft de Persoonsgegevens door aan volgende (categorieën)
ontvangers:</p>
<ul>
    <li>Verwerkers die voorzien in statistisch onderzoek en analyses;</li>
    <li>Verwerkers die voorzien in hosting van deze Website en aanverwante databases;</li>
    <li>Verwerkers en verwerkingsverantwoordelijken die voor BV PINGUINO optreden
als onderaannemer bij het uitvoeren van de Diensten</li>
<li>Vennootschappen die verbonden zijn met BV PINGUINO.<sup>2</sup></li>
</ul>
<p>Persoonsgegevens kunnen buiten de Europese Economische Ruimte worden verwerkt, BV
PINGUINO zal ervoor zorgen dat passende of geschikte waarborgen worden genomen.
Betrokkenen kunnen mits schriftelijk verzoek een kopie bekomen van de gewaarborgde
maatregelen</p>
<p>BV PINGUINO kan zijn bedrijfsstructuur wijzigen door de juridische vorm te wijzigen,
fusie, overnames en verkoop. In dergelijke transacties worden Persoonsgegevens
doorgegeven conform deze Privacyverklaring en de toepasselijke wetgeving inzake
gegevensbescherming.</p>
<p>Anonieme gegevens kunnen steeds worden doorgeven, al dan niet voor commerciële
doeleinden.
</p>
<p><sup>2</sup>  Zoals gedefinieerd in artikel 11 van het Wetboek van Vennootschappen</p>

<p><h3>6. Duur van de bewaring van de gegevens</h3></p>
<p>De Persoonsgegevens worden door BV PINGUINO bewaard voor volgende termijnen</p>

<table>
    <tr>
        <th>Gegevens</th>
        <th>Duur</th>
    </tr>
    <tr>
        <td>Gegevens bezoek website</td>
        <td>Duur sessie</td>
    </tr>
    <tr>
        <td>Contactinformatie</td>
        <td>7 jaar</td>
    </tr>
    <tr>
        <td>Reservatie informatie</td>
        <td>7 jaar</td>
    </tr>
    <tr>
        <td>Bestelling informatie</td>
        <td>7 jaar</td>
    </tr>
    <tr>
        <td>Sollicitatie informatie </td>
        <td>4 maand</td>
    </tr>
</table>

<p><h3>7. Uw rechten</h3></p>
<p>U heeft het recht heeft ons te verzoeken tot inzage van en rectificatie of wissing van
Persoonsgegevens of om beperking van de u betreffende verwerking, alsmede het recht tegen
verwerking van bezwaar te maken en het recht op gegevensoverdraagbaarheid, door zich te
richten tot BV PINGUINO Nobels-peelmanstraat 10, 9100 Sint-Niklaas of
info@pinguino.be met een gedagtekend en ondertekend verzoek, met bijgevoegd een kopie
van uw identiteitskaart<sup>3</sup> of uw e-mail voorzien van een elektronische handtekening.
</p>

<p>Binnen 1 maand na de indiening van het verzoek zal u worden meegedeeld welk gevolg
hieraan werd gegeven.</p>
<p>U beschikt over het recht om uw toestemming voor een bepaalde verwerking te allen tijde in
te trekken, zonder dat dit afbreuk doet aan de rechtmatigheid van de verwerking op basis van
de toestemming vóór de intrekking daarvan. In het geval u uw toestemming heeft gegeven
voor het ontvangen van nieuwsbrieven en reclame kan u die terug intrekken door uw
accountinstellingen aan te passen of gebruik te maken van de “unsubscribe/uitschrijven”-link
in deze communicatie.
</p>
<p>Tot slot heeft u steeds het recht om klacht in te dienen bij de toezichthoudende autoriteit.</p>
<p>Er wordt geen gebruik gemaakt van geautomatiseerde besluitvorming, waaronder profilering,
bij de verwerking van Persoonsgegevens.</p>

<p><sup>3</sup> Gelieve op de kopie van uw identiteitskaart volgende velden te anonimiseren: kaartnummer &
rijksregisternummer.</p>

<p><h3>8. Anonieme gegevens</h3></p>
<p>Het is BV PINGUINO toegestaan om niet-persoonlijke en anonieme gegevens te
verzamelen wanneer de Website wordt bezocht en Persoonsgegevens te anonimiseren. Deze
gegevens laten BV PINGUINO niet toe om een individuele persoon te identificeren en
deze gegevens kunnen met derden worden gedeeld, ook voor statistische doeleinden.</p>

<p><h3>9. Cookies</h3></p>
<p>Deze Website maakt gebruik van cookies om de browse-ervaring te verbeteren. Raadpleeg
onze Cookieverklaring voor meer informatie over het gebruik van cookies.</p>

<p><h3>10. Wijzigingen</h3></p>
<p>BV PINGUINO houdt zich het recht voor deze Privacyverklaring steeds te wijzingen.</p>
<p>Deze Privacyverklaring werd het laatst gewijzigd op 3 januari 2023.</p>

<p><h3>11. Varia</h3></p>
<p>Indien één of meerdere clausules uit deze Privacyverklaring door een rechterlijke tussenkomst
gedeeltelijk of volledig nietig of niet-bindend wordt verklaard, heeft dit geen enkele invloed op
de geldigheid van de overige clausules of op de geldigheid van de gehele Privacyverklaring.
Indien BV PINGUINO de bewuste clausule(s) wensen te wijzigen of vervangen, dient
de gewijzigde of nieuwe clausule zo nauw als mogelijk aan te sluiten bij de nietige of nietbindend verklaarde clausule(s).
</p>
<p>Het nalaten van BV PINGUINO om de strikte naleving van de bepalingen van deze
Privacyverklaring op te eisen, zal niet worden opgevat als een enige afstand of verwerping
daarvan.</p>

<p><h3>12. Toepasselijk recht</h3></p>
<p>Elke betwisting of vordering met betrekking tot de verwerking van persoonsgegevens en
Privacyverklaring of elk gegeven dat hierin vermeld staat, wordt door het Belgisch recht
beheerst en valt onder de exclusieve bevoegdheid van de hoven en rechtbanken van het
gerechtelijk arrondissement waar de zetel van BV PINGUINO is gelegen (België)</p>

<p><h3>13. Contact</h3></p>
<p>Indien u na het lezen van deze Privacyverklaring nog vragen of opmerkingen heeft in verband
met het beleid ter bescherming van de persoonlijke levenssfeer of omtrent cookies, kan u
contact opnemen met de contactpersoon op het adres Nobels-peelmanstraat 10, 9100 SintNiklaas of via het e-mailadres info@pinguino.be.</p>
<p>Voor bijkomende algemene informatie betreffende de bescherming van Persoonsgegevens
kan u zich richten tot de Commissie voor de bescherming van de persoonlijke levenssfeer met
adres te 1000 Brussel, Drukpersstraat 35, tel: 02/274.48.00 of via e-mail
commission@privacycommission.be (www.privacycommission.be).</p>
            </div>
        )
    }
}

export default PrivacyPolicyView